import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginService } from '../services/login/login.service';

@Injectable({
  providedIn: 'root'
})

export class AuthInterceptorService implements HttpInterceptor{

  constructor(private authenticationService: LoginService) { }
  
   // Intercept token to header  if available
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let currentUser = this.authenticationService.currentUserValue;
    // Authorization header
    const headers = { Authorization: `token ${currentUser.token}` };
    // Add headers for working properly when browser is Internet Explorer
    if (this.isIE()) {
        headers['Cache-Control'] = 'no-cache';
        headers['Pragma'] = 'no-cache';
        headers['Expires'] = 'Sat, 01 Jan 2000 00:00:00 GMT';
    }
    
    if (this.authenticationService.isAuthenticated()) {
      request = request.clone({
        setHeaders: headers
      });
    }
    return next.handle(request);
  }

  // Returns true when client browser is IE
  isIE(): boolean {
    const ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    return ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
  }
}
