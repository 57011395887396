import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Page404Component } from './views/pages/page404/page404.component';
import { LoginComponent } from './views/pages/login/login.component';
import { AuthGuard } from './_helpers/auth.guard';

const routes: Routes = [  
  { path: 'login', component: LoginComponent},
  { path: 'panel', loadChildren: () => import('./routing/panel/panel.module').then(m => m.PanelModule), canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
  { path: '',   redirectTo: '/login', pathMatch: 'full' },
  { path: '**',  component: Page404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
