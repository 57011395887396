import { MediaFile } from './media-file';
export class User {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  images: MediaFile;
  status: boolean;
  username: string;
  password: string;  
  token: string;

  constructor(data: any){
      this.id = data.user_guid;
      this.first_name = data.first_name;
      this.last_name = data.last_name;
      this.email = data.email;
      this.images = data.image;
      this.status = data.deleted;
      this.username = data.username;
      this.password = data.password;      
      this.token = data.token;
  }
}
