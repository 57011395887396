<!-- begin:: Page -->
<div class="kt-grid kt-grid--ver kt-grid--root">
    <div class="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v4 kt-login--signin" id="kt_login">
        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" style="background-image: url(../../../../assets/images/bg/login-bg.jpg);">
            <div class="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
                <div class="kt-login__container">
                    <div class="kt-login__logo">
                        <a href="#">
                            <img src="../../../../assets/images/logos/POSiBLE-logo.png" class="img-fluid">
                        </a>
                    </div>
                    <div class="kt-login__signin">
                        <div class="kt-login__head">
                            <h3 class="kt-login__title">Iniciar sesión</h3>
                        </div>
                        <div class="alert alert-solid-danger alert-bold" role="alert" *ngIf="errorLogin != ''">
                            <div class="alert-text">{{ errorLogin }}</div>
                        </div>
                        <form class="kt-form" [formGroup]="loginForm">
                            <div class="form-group mb-0" [class.validated]="isSubmitted">
                                <input class="form-control" type="text" placeholder="Correo electrónico" autocomplete="off" formControlName="email" required>
                                <div *ngIf="isSubmitted && formFields.email.errors">
                                    <div class="invalid-feedback" *ngIf="formFields.email.errors.required">
                                        Correo electrónico requerido.
                                    </div>
                                    <div class="invalid-feedback" *ngIf="formFields.email.errors.email">
                                        Correo electrónico inválido.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group" [class.validated]="isSubmitted">
                                <input class="form-control" type="password" placeholder="Contraseña" formControlName="password">
                                <div *ngIf="isSubmitted && formFields.password.errors">
                                    <div class="invalid-feedback" *ngIf="formFields.password.errors.required">
                                        Contraseña requerida.
                                    </div>
                                </div>
                            </div>
                            <!-- Ignore until implements -->
                            <!-- <div class="row kt-login__extra">
                                <div class="col">
                                    <label class="kt-checkbox">
                                        <input type="checkbox" name="remember"> Remember me
                                        <span></span>
                                    </label>
                                </div>
                                <div class="col kt-align-right">
                                    <a href="javascript:;" id="kt_login_forgot" class="kt-login__link">Forget Password ?</a>
                                </div>
                            </div> -->
                            <div class="kt-login__actions">
                                <button id="kt_login_signin_submit" class="btn btn-brand btn-pill kt-login__btn-primary" 
                                [class.kt-spinner]="btnLoading"
                                [class.kt-spinner--right]="btnLoading"
                                [class.kt-spinner--sm]="btnLoading"
                                [class.kt-spinner--light]="btnLoading"   
                                [disabled]="btnLoading"                               
                                (click)="login()">Entrar&nbsp;</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>