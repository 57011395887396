import { environment } from 'src/environments/environment';

export const Globals = {
    urlPosibleBase: `${environment.apiUrl}api/`,
    users: {
        root: 'users/',
        login: 'login/',
        logout: 'logout/',
        multiselect: 'multiselect/'
    },
    projects: {
        root: 'projects/',
        resume: 'resumen'
    },
    publications: {
        root: 'publications/',
        categories: 'publicationCategories',
        types: 'publicationTypes'
    },
    topics: {
        root: 'topics/',
        multiselect: 'multiselect/',
        unique_name: 'isAvailable/'
    },
    publicity: {
        root: 'highlights/',
    },
    states: {
        root: 'states/',
    },
    announcements: {
        root: 'convocatorias/',
    },
    panel: {
        root: 'panel/',
        details: '/panelDetails',
        create: 'createPanel/'
    },
    guidelines:{
        root: 'advertisingSchedule',
        dashboard: 'dashboard/'
    }
};
