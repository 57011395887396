import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from 'src/app/services/login/login.service';
import { User } from 'src/app/models/user';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  
  // Form that includes inputs for login
  loginForm: FormGroup;
  // Indicates if request to login is send
  isSubmitted  =  false;
  // Variable that show a spinner 
  btnLoading = false;
  // String variable that stores an error message, if exists an error
  errorLogin = '';

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public loginService: LoginService
  ) {
    this.loginService.isLoggedIn(); 
  }

  ngOnInit(): void {
    this.loginForm  =  this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }
  
  // Getter of email
  get email() { return this.loginForm.get('email'); }
  
  // Getter of password
  get password() { return this.loginForm.get('password'); }
  
  // Convenience getter for easy access to form fields
  get formFields() { return this.loginForm.controls; }

  // Method that receives data access to login
  login() : void{
    this.errorLogin = '';
    this.isSubmitted = true;
    this.btnLoading = true;

    if(this.loginForm.invalid){
      this.btnLoading = false;
      return;
    }
    let user = new User({});
    user.email = this.email.value;
    user.password = this.password.value;
    this.loginService.login(user).pipe(first())
      .subscribe(
        results => {    
            if (results['Data']['token'] && results['Data']['user']['is_superuser'] == true) {
              this.router.navigateByUrl('panel/dashboard');
            }else{
              this.errorLogin = "Acceso solo administradores"; 
              this.isSubmitted = false;
              this.btnLoading = false;
              this.router.navigateByUrl('login');
            }
        },
        error => {
          this.errorLogin = error.error.message;          
          this.isSubmitted = false;
          this.btnLoading = false; 
        })// subscribe
  }
}
