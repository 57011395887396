import { Injectable } from '@angular/core';
import { Globals } from '../../classes/globals';
import { User } from '../../models/user';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  // variable to store the currentUsersSubject BehaviorSubject
  private currentUserSubject: BehaviorSubject<any>;
  // variable to store the currentUser observable
  public currentUser: Observable<any>;
  // variable that store the import of Globals Class that will help us to retrieve the urls for every service
  globals = Globals;
  // Url of users api
  baseUrl = `${this.globals.urlPosibleBase}${this.globals.users.root}`;

  public loginInfo: User;

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private handler: HttpBackend) {

    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('user')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.getLoginInfo();
  }


  // Validate if the currentUserSubject exists, if exists get its value.
  public get currentUserValue() {
    if (this.currentUserSubject && this.isAuthenticated()) {
      return this.currentUserSubject.value;
    } else {
      this.currentUserSubject.next(null);
      return false;
    }
  }
  
  // Return the variable that is stored in storage 
  getLoginInfo(): void{
    if (localStorage.getItem('user') != '') {
      this.loginInfo = JSON.parse(localStorage.getItem('user'));
    }
  }

  // Verifies if user is logged in
  isLoggedIn(): void{
    if (!this.loginInfo) {
      this.router.navigate(['login']);
    }else{
      this.router.navigateByUrl('panel');
    }
  }

  // Verifies if user is authenticatec
  isAuthenticated(): boolean {
    if (this.loginInfo) {
      const token = this.loginInfo.token;
      if (token) {
        return true;
      }
      return false;
    }
    return false;
  }


  /**
   * Login
   * @param User 
   * @returns token if the authentication is correct, error if not
   */
  login(user: User){

    let data = {
      username: user.email,
      password: user.password
    }
    this.httpClient = new HttpClient(this.handler);// Pass handler in order to exclude this request from interceptor
    return this.httpClient.post(`${this.baseUrl}${this.globals.users.login}`, data)
      .pipe( 
        map(result => {
            if (result['Data']['token'] && result['Data']['user']['is_superuser']) {         
              let local = result['Data']['user'];   
              local.token = result['Data']['token'];
              this.loginInfo = new User(local);  
              localStorage.setItem('user', JSON.stringify(this.loginInfo)); 
              this.currentUserSubject.next(local);
            }
            return result;
          })
        );
  }
  
  // Remove the token of the local storage.
  logout(): boolean{
    const currentUser = localStorage.getItem('user');
    if (currentUser) {      
      // remove user from local storage to log user out
      localStorage.removeItem('user');
      this.currentUserSubject.next(null);
      this.router.navigate(['login']);
      this.loginInfo = null;
      return true;
    }
    return false;
  }

}
